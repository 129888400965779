@font-face {
  font-family: "Poppins-Black.ttf";
  src: url(../assets/fonts/Poppins/Poppins-Black.ttf);
}

@font-face {
  font-family: "Courgette-Regular.ttf";
  src: url(../assets/fonts/Courgette/Courgette-Regular.ttf);
}

:root {
  --main-color: #20c997;
  --main2-color: #068a65;
  --mainprimary-color: #2e2f62;

}


/* pre-loader */
.loader{
  width: 100px;
  height: 100px;
  margin: 30px auto 0;
  position: relative;
}
.loader .inner-loader{
  background: #555;
  width: 100px;
  height: 10px;
  opacity: .75;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  animation: spin 20s infinite linear;
}
.loader .inner-loader .red{ background: #f16362; }
@keyframes spin{
  0% { transform:  translateX(-50%) translateY(-50%) rotate(0deg); }
  100% { transform:  translateX(-50%) translateY(-50%) rotate(360deg); }
}


/*swipper*/
.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
  display: none !important;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 0px);
}

.mySwiper .swiper-pagination-bullet {
  display: none !important;
}

.swiper {
  width: 100%;
  /* height: 100%; */
}

.swiper-box {
  display: flex;
  position: relative;

}

.swiper-box img {
  position: absolute;
  z-index: 10;
  right: 0;


}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*swipper end*/



/*btn*/

.ad-btn {
  align-items: center;
  appearance: button;
  background-color: var(--mainprimary-color);
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "RM Neue", sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  padding: 10px 21px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out, box-shadow .13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.ad-btn:active {
  background-color: var(--mainprimary-color);
}

.ad-btn:hover {
  background-color: var(--mainprimary-color);
}


/*social start */

.socialbar {
  border: 1px solid var(--mainprimary-color);
  background-color: var(--mainprimary-color);

}

.socialbar p {
  font-size: 12px;
  color: white;
  /* width: 40px; */
  text-align: center;
}

.socialbar i {
  /* width: 10px; */
  display: block;
  margin-bottom: 10px;
  font-size: 30px;
  border-radius: 50%;
  /* padding: 10px; */
  color: white;
  transition: transform 0.3s ease;
  /* background-image: url('../assets/images/whitetab.png'); 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
  animation: blink 0.5s infinite alternate;
  cursor: pointer;

}

.socialbar i:hover {
  animation-play-state: paused;
  /* Pause the animation on hover */
  color: var(--main2-color);
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@media (max-width:500px) {
  .social-border {
    border-right: 1px solid white;
    margin-bottom: 0;


  }

}

@media (min-width:500px) {
  .socialbar {
    top: 56%;
    transform: translateY(-50%);
    position: fixed;
    /* bottom: 0; */
    right: 0;
    padding: 10px;
    z-index: 100;


  }

  .social-border {
    border-bottom: 1.5px solid white;
    margin-bottom: 2px;



  }
}

.image-container {
  /* background-image: url('https://www.kauveryhospitalsbangalore.com/assets/uploads/speciality/arthroplasty-icon_64e1996f18dae.png'); */
  color: green;
  /* Change to the desired color */
  /* OR */
  background-blend-mode: color;
  /* Blend with the background color */
  background-repeat: no-repeat;
  /* filter: hue-rotate(70deg) sepia(50%); */
  filter: brightness(0.5) saturate(2) hue-rotate(120deg);

}

.image-container img {
  width: 100px;

}


/* @media (min-width:1000px) {
    .socialbar{
      position: fixed;
      flex-direction: column;
      top: 53%;
      transform: translateY(-50%);
      right: 0;
      padding: 10px;
      z-index: 100;
      
    }
  } */
.socialbar {
  /* position: fixed; */
  padding: 10px;
  z-index: 100;
  right: 0;

  /* flex-direction: column; */
}

@media (min-width: 250px) and (max-width:500px) {
  .socialbar {
    padding: 20px;
    /* width: 100%; */
    display: flex;
    position: fixed;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 0;
    right: 0;
    gap: 5px;
  }
}



/*social end */

/* header */
.logo-img {
  width: 100%;
  height: 60px;
  /* filter: none|blur()|brightness()|contrast()|drop-shadow()|grayscale()
        |hue-rotate()|invert()|opacity()|saturate()|sepia()|url(); */

}

@media (min-width:400px) {
  .logo-img {
    width: 100%;
    height: 80px;

  }
}

.logo-img1 {
  width: 100%;
  height: 60px;
  filter: brightness(0.5) invert() grayscale(10) hue-rotate(120deg);
}


@media (min-width:400px) {
  .logo-img1 {
    width: 100%;
    height: 100px;
    filter: brightness(0.5) invert() grayscale(10) hue-rotate(120deg);
    /* filter : grayscale(10);  */
  }
}

h6 {
  color: #178066;
}

/*header close */
.hero_area {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hero_area .hero_bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  overflow: hidden;
  z-index: -1;
}

.hero_area .hero_bg_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: bottom right;
  object-position: bottom right;
}

.sub_page .hero_area {
  min-height: auto;
  background-color: #178066;
}

.sub_page .hero_area .hero_bg_box {
  display: none;
}

#topbar i {
  font-size: 24px;

}

#topbar a {
  /* background-color: var(--main-color); */
  color: var(--mainprimary-color);

}
.top-social{
  font-size: 18px;
}

#topbar a:hover {
  /* background-color: var(--main-color); */
  color: var(--main2-color);

}

@media (min-width:1000px) {
  .navbar {
    height: 80px;
  }
}


.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;

}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #000000;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* Add styles for the after element */
.navbarNav link:after {
  content: "";
  position: absolute;
  bottom: -2px;
  /* Adjust as needed */
  left: 0;
  width: 0%;
  height: 2px;
  background-color: var(--main-color);
  /* Change to your desired color */
  transition: width 0.3s ease;
}

/* Styles for hover effect */
.navbarNav link:hover:after {
  width: 100%;
}

.navbarNav link,
.navbarNav link:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 600;
  color: var(--main2-color);
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbarNav link i,
.navbarNav link:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbarNav>ul>li>link:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: var(--main-color);
  transition: width 0.3s ease;
  visibility: hidden;
}

.navbarNav link:hover:after,
.navbarNav .active:after {
  width: 100%;
  visibility: visible;
}

.navbarNav link:hover,
.navbarNav .active,
.navbarNav .active:focus,
.navbarNav li:hover>link {
  color: rgb(17, 99, 131);
}

/* State when the navbarNav is collapsed */
.nav-link {
  font-size: 15px;
  font-weight: 600;
  color: var(--mainprimary-color);
  font-weight: 600;
}

.nav-link:hover {
  font-size: 15px;
  font-weight: 600;
  color: var(--main2-color);
  border-bottom: 1px solid var(--main2-color);
  width: 100%;
}

.nav-link .active {
  font-size: 15px;
  font-weight: 600;
  color: var(--main2-color);
  border-bottom: 1px solid var(--main2-color);
  width: 100%;
}


#navbar {
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);
}


/* .navbar{
background-color:#fff;
box-shadow: 0px 25px 20px -20px rgba(0, 0, 0, 0.45);

} */
.btn-book {
  background-color: var(--mainprimary-color);
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  width: 130px;
  text-align: center;
}

.btn-book:hover {
  background-color: white;
  color: var(--mainprimary-color);
  padding: 10px 15px;
  border: 1px solid var(--mainprimary-color);
  border-radius: 5px;
}

.navbar-nav {
  text-align: center;
  gap: 10px;
  display: flex;
  justify-content: center;
}

.border-design {
  border-bottom: 5px solid var(--mainprimary-color);

}

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

.dropdown .dropdown-item {
  color: var(--mainprimary-color);
}

.dropdown .dropdown-item:hover {
  background-color: var(--mainprimary-color);
  color: white;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}



/* Background image styles */
.bg-image {
  background-image: url('https://img.freepik.com/free-photo/park-lujiazui-financial-center-shanghai-china_1127-2860.jpg?t=st=1710751715~exp=1710755315~hmac=f6ec9b31abda386d7588f32a77029868afd1770cb6abfda34cd7b1291e236248&w=900');
  background-size: cover;
  background-position: center;
  height: auto;

}

.hero-left {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;

}

.hero-left img {
  width: 100%;
  /* height: 200px; */
  object-fit: cover;

}

.bannerAll {
  width: 100%;
  height: 200px;
  object-fit: cover;
  background-image: url('https://img.freepik.com/premium-photo/attractive-female-doctor-front-medical-group_358354-9692.jpg?w=1060');
  background-size: cover;
  background-position: center;
}

.bannerAll p {
  font-size: 40px;
  font-weight: 700;
  padding-top: 60px;
  color: var(--mainprimary-color);
  padding-left: 20px;
}

.bannerAboutAll {
  width: 100%;
  height: 200px;
  padding-top: 60px;
  object-fit: cover;
  background-image: url('/src/assets/images/banner2-1.jpg');
  background-size: cover;
  background-position: center;
}

.bannerAboutAll p {
  font-size: 16px;
  font-weight: 700;

  color: var(--mainprimary-color);
  padding-left: 20px;
}

.bannerAboutAll h1 {
  font-size: 30px;
  font-weight: 700;
  /* padding-top: 60px; */
  color: var(--mainprimary-color);
  padding-left: 20px;
}

@media (min-width:700px) {
  .bg-image {
    background-image: url('https://img.freepik.com/free-photo/park-lujiazui-financial-center-shanghai-china_1127-2860.jpg?t=st=1710751715~exp=1710755315~hmac=f6ec9b31abda386d7588f32a77029868afd1770cb6abfda34cd7b1291e236248&w=900');
    background-size: cover;
    background-position: center;
    position: relative;

  }

  .hero-left {
    width: 100%;
    height: 500px;
    object-fit: cover;

  }

  .hero-left img {
    width: 100%;
    height: 500px;
    object-fit: cover;

  }

  .bannerAll {
    width: 100%;
    height: 460px;
    object-fit: cover;
    background-image: url('https://img.freepik.com/premium-photo/attractive-female-doctor-front-medical-group_358354-9692.jpg?w=1060');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bannerAll p {
    font-size: 70px;
    font-weight: 700;
    padding-top: 180px;
    color: var(--mainprimary-color);
    padding-left: 20px;
  }

  .bannerAboutAll {
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-image: url('/src/assets/images/banner2-1.jpg');
    background-size: cover;
    background-position: center;
  }

  .bannerAboutAll p {
    font-size: 16px;
    font-weight: 700;
    padding-top: 150px;
    color: var(--mainprimary-color);
    padding-left: 20px;
  }
  
  .bannerAboutAll h1 {
    font-size: 50px;
    font-weight: 700;
    /* padding-top: 60px; */
    color: var(--mainprimary-color);
    padding-left: 20px;
  }
}


.our-mission {
  border: 2px solid var(--mainprimary-color);
}
.our-mission h4{
  text-align: center;
  color: var(--mainprimary-color);
  font-weight: 600;
  font-size: 30px;
}

/* health package */
.health-img {
  /* margin-top: 50px; */
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.health-img img {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;


}

@media (min-width:1000px) {
  .health-card {
    border: 1px solid var(--mainprimary-color);
    width: 210px;
    height: auto;
    margin-top: -100px;
    margin-left: -50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    z-index: -99;

  }

  .health-img img {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    object-fit: cover;


  }
}

.health-card {
  border: 1px solid var(--mainprimary-color);
  /* width: 100%; */
  height: auto;
  /* margin-top: -100px;
  margin-left: -50px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;

}

.health-left {
  padding: 70px;
}

.health-left .btn-1 {
  display: inline-block;
  padding: 14px;
  background-color: var(--mainprimary-color);
  color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid var(--mainprimary-color);
  border-radius: 5px;
}

.health-left .btn-1:hover {
  background-color: transparent;
  color: var(--mainprimary-color);
}


/* Form column styles */
.form-column {
  padding-left: 15px;
  /* Example padding, adjust as needed */
  padding-right: 15px;
  /* Example padding, adjust as needed */
  margin-bottom: 20px;
  /* Space between form and background image */
}

@media (min-width: 768px) {

  /* .bg-image {
    height: 50vh;
  } */

  .form-column {
    margin-bottom: 0;
  }
}



/* Color of 3 lines */

.navbar-toggler.collapsed .toggler-icon {
  background: black
}

.hero-bg {
 
  background-image: url('https://img.freepik.com/free-photo/park-lujiazui-financial-center-shanghai-china_1127-2860.jpg?t=st=1710751715~exp=1710755315~hmac=f6ec9b31abda386d7588f32a77029868afd1770cb6abfda34cd7b1291e236248&w=900');
  background-repeat: no-repeat;
  background-size: cover;
}
/* @media (min-width:1000px) {
  .hero-bg {
    height: 100%;
    background-image: url('https://img.freepik.com/free-photo/park-lujiazui-financial-center-shanghai-china_1127-2860.jpg?t=st=1710751715~exp=1710755315~hmac=f6ec9b31abda386d7588f32a77029868afd1770cb6abfda34cd7b1291e236248&w=900');
    background-repeat: no-repeat;
    background-size: cover;
  }
} */

/*banner  start*/
.ban-h1 {
  font-family: "Poppins-Black.ttf";
  font-size: 45px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  color: transparent;

}

.ban-p {
  color: white;
  font-size: 20px;

}

.back-color {
  background-color: #178066;
  position: relative;

}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  border: 0px;
  z-index: 2;
}



/*banner wave end*/


/*Our department start*/

.loader {
  color: #f35626;
  background: #f35626;
  width: 10px;
  height: 10px;
  margin: 50px auto;
  box-shadow: 15px 15px 0 0, -15px -15px 0 0, 15px -15px 0 0, -15px 15px 0 0, 0 15px 0 0, 15px 0 0 0, -15px 0 0 0, 0 -15px 0 0;
  animation: animate 2s linear infinite;
}

@keyframes animate {
  0% {
    -webkit-filter: hue-rotate(0deg);
  }

  50% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0, 0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }

  75% {
    box-shadow: 20px 20px 0 0, -20px -20px 0 0, 20px -20px 0 0, -20px 20px 0 0, 0 10px 0 0, 10px 0 0 0, -10px 0 0 0, 0 -10px 0 0;
  }

  100% {
    transform: rotate(360deg);
    -webkit-filter: hue-rotate(360deg);
  }
}

.carousel-img {
  position: absolute;
  opacity: 0.5;

}

.carousel-h1 {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000101;
  font-weight: bold;
  font-family: "Courgette-Regular.ttf";
}

.card {
  /* box-shadow: 0px 0px 11px 3px rgba(233,237,237,0.34); */
  box-shadow: #20c997 3px 3px, #20c997 5px 5px;
  border-radius: 5px;
}

.crd-img-div {
  height: 140px;
}

.card-img-top {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.card-title {
  font-size: 22px;
  /* justify-content: center;
   align-items: center;
   display: flex; */
  /* font-family: "Courgette-Regular.ttf"; */
  color: var(--mainprimary-color);
  font-weight: 600;
}

@media only screen and (max-width:990px) {
  .mySwiper {
    height: 350px;
  }

  .crd-img-div {
    height: 200px;
  }
}


/*Our department end*/

/* about start */
.background-about {
  /* image url  */
  background-image: url("/src/assets/images/banner2-1.jpg");

  /* make the image full height */
  height: 400px;

  /* center and scale the image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* use the following only if if you are able to scroll past the image to see a white area (this is the <body> peeking out) above or below the image */
  overflow: hidden;
  opacity: 0.7;


}

.background-about p {
  font-size: 18px;
  font-weight: 700;
  padding-top: 100px;
}

.background-about h1 {
  font-size: 60px;
  font-weight: 800;
}

.about-banner {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-banner img {
  /* background-image: url('/src/assets/images/banner2-1.jpg');
    background-repeat: no-repeat; */
  width: 100%;
  height: 40%;
  object-fit: cover;

}

.about-banner p {
  position: absolute;
}

.aboutgrid {
  display: grid;
  grid-template-columns: 70px [one-start] 100px [two-start] 300px [one-end] 100px [two-end] 150px 100px;
  grid-template-rows: [one-start] 150px [two-start] 180px [one-end] 100px [two-end];
}

@media (max-width:1050px) {
  .aboutgrid {
    display: grid;
    grid-template-columns: 0px [one-start] 50px [two-start] 300px [one-end] 100px [two-end] 150px 100px;
    grid-template-rows: [one-start] 100px [two-start] 180px [one-end] 100px [two-end];
  }
}


.one {
  grid-area: one;
  background: lightblue;
  width: 100%;
  height: 100%;
}

.one img {
  width: 400px;
  height: 300px;

}

.two {
  grid-area: two;
  background: lightgreen;
}

.two img {
  width: 400px;
  height: 300px;

}

.abt-box {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 5%;

}

.nurseHome-icon {
  background-image: url('/src/assets/images/section-back-icon.png');
  height: 160px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.8; */
  background-repeat: no-repeat;

}
.admin-photo{
  margin-top: 10px;
  width: 100%;
  border-radius: 20px;
  
}
@media (min-width:1000px) {
  .admin-photo{
    margin-top: 50px;
    width: 100%;
    border-radius: 10px;
    padding: 0 20px ;
  }
}

.nurseHomeAdmin-icon {
  background-image: url('/src/assets/images/section-back-icon.png');
  height: 160px;
  width: 380px;
  display: flex;
  align-items: center;
  justify-items: center;
  /* opacity: 0.8; */
  background-repeat: no-repeat;

}

.admin-h1 {
  color: rgb(63, 61, 61);
  font-size: 42px;
  font-weight: bold;
}

.about-cnt {
  /* padding-left: 10px ; */
  padding-right: 10px;
}

.about-h1 {
  color: rgb(63, 61, 61);
  font-size: 40px;
  font-weight: bold;
  text-align: center;

}

@media (max-width:500px) {
  .about-h1 {
    color: rgb(63, 61, 61);
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    justify-content:center;
  }
}

@media (max-width:300px) {
  .admin-h1 {
    color: rgb(63, 61, 61);
    font-size: 40px;
    font-weight: bold;
  }
}

@media (max-width:500px) {
  .admin-h1 {
    color: rgb(63, 61, 61);
    font-size: 30px;
    font-weight: bold;
  }

  .nurseHomeAdmin-icon {
    background-image: url('/src/assets/images/section-back-icon.png');
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    /* opacity: 0.8; */
    background-repeat: no-repeat;

  }
}

@media (max-width:300px) {
  .about-h1 {
    color: rgb(63, 61, 61);
    font-size: 28px;
    font-weight: bold;
  }

  .admin-h1 {
    color: rgb(63, 61, 61);
    font-size: 20px;
    font-weight: bold;


  }

  .nurseHomeAdmin-icon {
    background-image: url('/src/assets/images/section-back-icon.png');
    height: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    /* opacity: 0.8; */
    background-repeat: no-repeat;

  }
}

.nurseAbout-icon {
  background-image: url('/src/assets/images/section-back-icon.png');
  height: 160px;
  width: 100%;
  display: flex;
  /* opacity: 0.8; */
  background-repeat: no-repeat;

}

.border-ab-us span {
  color: lightgray;
}

.doc-h1 {
  color: var(--mainprimary-color);
  font-size: 42px;
  font-weight: bold;
}

.tick {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

/* .abt-bg{
background-color: #42F5d4;
} */
.abt-img-div {
  height: 250px;
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* box-shadow: 0 7px 30px -10px rgba(0, 0, 0, 0.5); */
  border-radius: 30px;
  padding: 10px;
}

.read-more {

  font-size: 18px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  color: var(--mainprimary-color);
  margin-top: 3px;
  border-bottom: 1.5px solid var(--mainprimary-color);
  transition: all 0.3s ease 0s;
  animation: slideRightToLeft 5s ease-in-out infinite;
  /* Adjust duration and timing function as needed */
}

.serviceBox-about {
  /* color: #fff; */
  /* font-family: 'Poppins', sans-serif; */
  text-align: center;
  padding: 25px 25px 55px;
  position: relative;
  z-index: 1;

}

.serviceBox-about:before,
.serviceBox-about:after {
  content: '';
  background: linear-gradient(#42F5d4, #25f7d1);
  border-radius: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 15px;
  bottom: 0;
  z-index: -1;
  /* box-shadow: 0px 0px 8px 0px rgba(1, 0, 0, 0.24) inset; */
}

.serviceBox-about:after {
  background: #ffffff;
  border: 5px solid #00f1c5;
  left: 0;
  right: 10px;
  top: 15px;
  bottom: 5px;
  clip-path: polygon(30% 0, 100% 0, 100% 85%, 75% 100%, 0 100%, 0 20%);
}

.serviceBox-about .title {
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #42F5d4;
  color: transparent;
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.serviceBox-about .title span {
  font-weight: 700;
}

.serviceBox-about .description {
  color: #000000;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 0;
}

.serviceBox-about .read-more {
  color: #ffffff;
  font-size: 30px;
  /* line-height: 22px; */
  position: absolute;
  right: 15px;
  bottom: 9px;
  transition: all 0.3s ease 0s;
  animation: slideRightToLeft 2s ease-in-out infinite;
  /* Adjust duration and timing function as needed */
}

@keyframes slideRightToLeft {
  0% {
    right: 10px;
  }

  50% {
    right: 5%;
    /* Change this value to move halfway across the container */
  }

  100% {
    right: 10px;
    /* Change this value to reach the left edge */
  }
}

.serviceBox-about .read-more:hover {
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 3px 3px rgba(255, 255, 255, 0.1);
  animation-play-state: paused;
}

@media only screen and (max-width:990px) {
  .serviceBox-about {
    margin: 0 0 30px;
  }
}

/* about end */

/*=============================
	Start Feautes CSS
===============================*/
.BrushCursor{
  width: 100%;
  height: 100%;
}

.running {
  margin-top: 50px;
  position: absolute;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: bold;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  /* padding: 50px 0; */
}

@media only screen and (min-width: 500px) {
  .running {
    font-size: 1rem;
  }
}
@media (min-width:500px) and (max-width: 900px) {
  .running {
    font-size: 30px;
  }
}

@media only screen and (min-width: 900px) {
  .running {
    font-size: 40px;
  }
}

.p1 {
  /* text-shadow: 1px 1px 1px black, -1px -1px 1px black; */
  color: var(--main2-color);
}

.p2 {
  color: var(--mainprimary-color);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  animation: text-animation 3s ease-in-out forwards alternate infinite;
  /* text-shadow: 1px 1px 1px black, -1px -1px 1px black; */
}

.p3 {
  height: 20%;
  color: transparent;
}

@keyframes text-animation {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 0, 0% 1%, 0% 100%, 0% 100%);
  }
}

.cursor-run {
  position: absolute;
  top: 50%;
  height: 60%;
  width: 60%;
  transform: translate(0, -50%);
  border-right: 2px solid var(--mainprimary-color);
  animation: cursor-animation 3s ease-in-out forwards alternate infinite;
}

@keyframes cursor-animation {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}
@media (min-width:700px) {
  .page-list{
    margin-top: 140px;
  }
  
}
@media (max-width:700px) {
  .page-list{
    margin-top: 70px;
  }
  .running {
    margin-top: 20px;
  }
  
}


.Feautes {
  padding-top: 0;
  font-family: 'Poppins', sans-serif;
}

.Feautes.index2 {
  padding-top: 100px;

}

.Feautes .section-title {
  /* margin-top: -100px; */
  width: 100%;
  margin: auto;
  font-size: 24px;
  font-size: bold;
}

@media (min-width:1000px) {
  .Feautes .section-title {
    /* width: 500px; */
    margin: 30px auto;
    font-size: 24px;
    font-size: bold;
  }

}

.Feautes.testimonial-page {
  padding-top: 100px;
}

.Feautes .single-features {
  text-align: center;
  position: relative;
  padding: 10px 20px;
}

.Feautes .single-features::before {
  position: absolute;
  content: "";
  right: -72px;
  top: 60px;
  width: 118px;
  border-bottom: 3px dotted var(--mainprimary-color);
}

.Feautes .single-features.last::before {
  display: none;
}

.Feautes .single-features .signle-icon {
  position: relative;
}

.Feautes .single-features .signle-icon {
  font-size: 50px;
  color: var(--mainprimary-color);
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 0;
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #dddddd;
  border-radius: 100%;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.Feautes .single-features:hover .signle-icon {
  background: var(--mainprimary-color);
  color: #fff;
  border-color: transparent;
}

.Feautes .single-features h3 {
  padding-top: 128px;
  color: #2C2D3F;
  font-weight: 600;
  font-size: 21px;
}

.Feautes .single-features p {
  margin-top: 20px;
}

/*=======================
	Start Fun Facts CSS
=========================*/
.overlay {
  position: relative;
}

.overlay:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: var(--mainprimary-color);
  opacity: 0.8;
}

.fun-facts {
  position: relative;

}

.fun-facts.section {
  padding: 120px 0;

}

.fun-facts {
  background: url('../assets/images/fun-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;

}

.fun-facts .single-fun i {
  position: absolute;
  left: 0;
  font-size: 62px;
  color: white;
  height: 70px;
  width: 70px;
  line-height: 67px;
  font-size: 28px;
  text-align: center;
  padding: 0;
  margin: 0;
  border: 2px solid white;
  border-radius: 0px;
  top: 50%;
  margin-top: -35px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border-radius: 50%;
}

.fun-facts .single-fun:hover i {
  color: var(--main-color);
  background: #fff;
  border-color: transparent;
}

.fun-facts .single-fun .content {
  padding-left: 80px;
}

.fun-facts .single-fun .counter {
  color: #fff;
  font-weight: 600;
  font-size: 30px;
  position: relative;
  /* display: block; */
  display: flex ;
  align-items: center;
  justify-content: center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s eas;
  margin-bottom: 7px;
}

@media (min-width:1000px) {
  .fun-facts .single-fun .counter {
    color: #fff;
    font-weight: 600;
    font-size: 30px;
    position: relative;
    /* display: block; */

    display: flex ;
    /* align-items: center; */
    justify-content: start;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s eas;
    display: block;
    margin-bottom: 7px;
  }
}
/* .fun-facts .single-fun .counter .counterPlus{
 margin: auto; 
 display: flex;
} */

.fun-facts .single-fun p {
  color: #fff;
  font-size: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .Feautes.index2 {
    padding-top: 70px;
  }

  .Feautes .single-features::before {
    display: none;
  }

  .Feautes .single-features {
    text-align: center;
    position: relative;
    padding: 10px 155px;
    margin: 10px 0;
  }

  .fun-facts.section {
    padding: 70px 0;
  }

  .fun-facts .single-fun {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 767px) {
  .Feautes.index2 {
    padding-top: 50px;
  }

  .Feautes .single-features .signle-icon {
    font-size: 42px;
    left: 50%;
    margin-left: -40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }

  .Feautes .single-features::before {
    display: none;
  }

  .Feautes .single-features {
    text-align: center;
    position: relative;
    padding: 0px;
    margin: 15px 0;
  }

  .Feautes .single-features h3 {
    padding-top: 105px;
    font-size: 20px;
  }

  .fun-facts.section {
    padding: 70px 0;
  }

  .fun-facts .single-fun {
    margin: 0;
    margin: 15px 0;
    position: relative;
  }

  .fun-facts .single-fun {
    margin: 35px 0;
    padding-top: 60px;
    text-align: center;
  }

  .fun-facts .single-fun i {
    left: 50%;
    margin-left: -35px;
    top: 0;
    position: absolute;
  }

  .fun-facts .single-fun p {
    text-align: center;
  }

  .fun-facts .single-fun .content {
    padding: 0;
  }
}

@media only screen and (max-width: 450px) {
  .Feautes .single-features .signle-icon {
    font-size: 42px;
    left: 50%;
    margin-left: -40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
  }

  .Feautes .single-features::before {
    display: none;
  }

  .Feautes .single-features {
    text-align: center;
    position: relative;
    padding: 0px;
    margin: 15px 0;
  }

  .Feautes .single-features h3 {
    padding-top: 105px;
    font-size: 20px;
  }

  .fun-facts.section {
    padding: 70px 0;
  }

  .fun-facts .single-fun {
    margin: 0;
    margin: 15px 0;
    position: relative;
  }

  .fun-facts .single-fun {
    margin: 35px 0;
    padding-top: 60px;
    text-align: center;
  }

  .fun-facts .single-fun i {
    left: 50%;
    margin-left: -35px;
    top: 0;
    position: absolute;
  }

  .fun-facts .single-fun p {
    text-align: center;
  }

  .fun-facts .single-fun .content {
    padding: 0;
  }
}

/* our special */
:root {
  --color1: #be2edd;
  --color2: #e056fd;
}

.specialBox {
  height: 100%;
  color: var(--mainprimary-color);
  background: linear-gradient(to top left, var(--color1) 49%, var(--color2) 50%);
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding: 25px 15px;
  position: relative;
  z-index: 1;
  border: 1px solid var(--mainprimary-color);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: var(--mainprimary-color) 1.95px 1.95px 2.6px;
}

.specialBox .special-icon {
  font-size: 60px;
  margin: 0 0 10px;
  text-align: center;

}

/* .specialBox .service-icon .specialist-icon{
    font-size: 40px;
} */
.specialBox .title {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.specialBox .description {
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 22px;
  margin: 0s;
}

.specialBox.orange {
  --color1: #FF7200;
  --color2: #FE9400;
}

.specialBox.gray {
  --color1: white;
  --color2: white;
}

.specialBox.blue {
  --color1: #0066EF;
  --color2: #018CF5;
}

@media only screen and (max-width: 1199px) {
  .specialBox {
    margin: 0 0 40px;
  }
}

/* our special end */

/* our departments */
.depart-h1 {
  color: rgb(63, 61, 61);
  font-size: 40px;
  font-weight: bold;
}

@media (max-width:500px) {
  .depart-h1 {
    color: rgb(63, 61, 61);
    font-size: 30px;
    font-weight: bold;
  }

}

.background-department {
  /* image url  */
  background-image: url("/src/assets/images/slider-bg-1.jpg");

  /* make the image full height */
  height: 450px;

  /* center and scale the image */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* use the following only if if you are able to scroll past the image to see a white area (this is the <body> peeking out) above or below the image */
  overflow: hidden;
  opacity: 0.7;


}

.background-department p {
  font-size: 18px;
  font-weight: 700;
  padding-top: 100px;
}

.background-department h1 {
  font-size: 60px;
  font-weight: 800;
}

/* our departments end */

/*administrators start*/
.nurse-icon {
  background-image: url('/src/assets/images/section-back-icon.png');
  height: 160px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-items: center;
  /* opacity: 0.8; */
  background-repeat: no-repeat;

}

.serviceBox {
  color: var(--main-color);
  font-family: 'Raleway', sans-serif;
  text-align: center;
  padding: 25px 20px 25px 80px;
  position: relative;
  z-index: 1;
}

.serviceBox:before,
.serviceBox:after {
  content: '';
  border: 4px solid var(--main-color);
  position: absolute;
  left: 36px;
  top: 0;
  right: 0;
  bottom: 0;
  clip-path: polygon(0 0, 65% 0%, 50% 100%, 0% 100%);
}

.serviceBox:after {
  clip-path: polygon(95% 15%, 100% 15%, 100% 100%, 95% 100%);
}

.serviceBox .service-icon {
  color: #fff;
  background-color: var(--main-color);
  font-size: 30px;
  line-height: 54px;
  border-radius: 15px;
  height: 60px;
  width: 60px;
  margin: 0 0 15px;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  top: 50%;
}

.serviceBox .title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin: 0 0 10px;
}

.serviceBox .description {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.serviceBox.orange {
  --main-color: var(--main2-color);
}

.serviceBox.pink {
  --main-color: var(--mainprimary-color);
}

@media only screen and (max-width: 1199px) {
  .serviceBox {
    margin: 0 0 40px;
  }
}



/*adminsitrators end*/





/* our doctors*/

.doctor-icon {
  width: 50px;
}

.why-us .content {
  padding: 30px;
  background: #20c997;
  border-radius: 4px;
  color: #fff;
}

.why-us .content h3 {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
}

.why-us .content p {
  margin-bottom: 30px;
}

.why-us .content .more-btn {
  display: inline-block;
  background: rgba(255, 255, 255, 0.2);
  padding: 6px 30px 8px 30px;
  color: #fff;
  border-radius: 50px;
  transition: all ease-in-out 0.4s;
}

.why-us .content .more-btn i {
  font-size: 14px;
}

.why-us .content .more-btn:hover {
  color: #20c997;
  background: #fff;
}

.why-us .icon-boxes .icon-box {
  text-align: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 40px 30px;
  width: 100%;
}

.why-us .icon-boxes .icon-box i {
  font-size: 40px;
  color: #20c997;
  margin-bottom: 30px;
}

.why-us .icon-boxes .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 30px 0;
}

.why-us .icon-boxes .icon-box p {
  font-size: 15px;
  color: #848484;
}

/* #hero {
    width: 100%;
    height: 100px;
    background: url("/src/assets/images/slider-bg-1.jpg") top center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: -200px;
  }
  @media (max-width:500px) {
    .hero-bg{
        width: 20px;
      }
  } */

#hero {
  width: 100%;
  height: 90vh;
  background: url("/src/assets/images/slider-bg-1.jpg") top center;
  background-size: cover;
  margin-bottom: -200px;
}

/* @media (max-width:500px) {
    #hero {
        width: 100%;
        height: 50%;
        background: url("/src/assets/images/slider-bg-1.jpg") top center;
        background-repeat: no-repeat;
        background-size: cover;
        
      }
  } */
#hero .container {
  position: relative;
}

#hero h1 {
  margin: 0;
  width: 550px;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  color: #2c4964;
}

#hero p {
  color: #2c4964;

}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 35px;
  margin-top: 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #1977cc;
}


@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 992px) {
  #hero {
    margin-bottom: 0;
    height: 35vh;
  }

  #hero .container {
    padding-bottom: 63px;
  }

  #hero h1 {
    margin: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #2c4964;
  }

}

@media (max-height: 600px) {
  #hero {
    height: 110vh;
  }
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f1f7fd;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #2c4964;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #1977cc;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/* .bg-img{
    background-image: url('../assets/images/doctorbg.webp');

} */
.our-team {
  padding: 10px 0 10px;
  background: #f7f5ec;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  height: 270px;
}

.our-team .pic {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .pic:before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background: #0ceec4;
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.2;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team .pic:before {
  height: 100%;
}

.our-team .pic:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #42f5d4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .pic img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .team-content {
  margin-bottom: 15px;
  padding: 5px;
}

.our-team .title {
  font-size: 16px;
  font-weight: 700;
  color: #4e5052;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.doctor-content{
  
}

.our-team .post {
  display: block;
  font-size: 10px;
  color: var(--mainprimary-color);
  font-weight: bold;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #42f5d4;
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: #fff;
  transition: all 0.3s ease 0s;
}

.our-team .social li a:hover {
  color: #42f5d4;
  background: #f7f5ec;
}

@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }
}

/* our doctors end */

/* contact */
.contact-form {
  background-color: #fff;
  padding: 0px 50px;
  width: 100%;
  /* padding-bottom: 20px; */
  /* padding-top: 20px; */
  z-index: 1;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-top: 3px solid var(--main-color);
}

@media (min-width:1000px) {
  .contact-form {
    background-color: #fff;
    padding: 0px 50px;
    width: 60%;
    /* padding-bottom: 20px; */
    /* padding-top: 20px; */
    z-index: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-top: 3px solid var(--main-color);
  }
}

.contact-form h2 {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  /* width: 100%; */
  margin-top: -20px;
}


.contact-form .input-box textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  /* margin: 14px 0; */
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box input {
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 20px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 10px 0;
  font-size: 14px;
  margin: -90px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

/* .contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: var(--main2-color);
  font-size: 12px;
  transform: translateY(-20px);

} */

.contact-form .input-box input[type="submit"] {
  /* width: 100%; */
  background: var(--main-color);
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid var(--main-color);
  transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
  background: #FFF;
  color: var(--main2-color);
}




.contact-layout {
  width: 100%;
  align-items: center;
  position: relative;
  background-image: url('../assets/images/map.jpeg');
  /* opacity: 0.8; */
  background-repeat: no-repeat;
  background-size: cover;

}

.form-container {
  /* background-image: url('../assets/images/map.jpeg'); */
  /* font-family: 'Roboto', sans-serif; */
  padding: 10px;
  position: relative;

}

.form-container .form-icon {
  color: var(--mainprimary-color);
  background-color: #fff;
  font-size: 40px;
  text-align: center;
  line-height: 80px;
  height: 80px;
  width: 80px;
  border: 3px solid var(--mainprimary-color);
  border-radius: 50% 50% 50% 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.form-container .form-horizontal {
  background: linear-gradient(var(--mainprimary-color) 25px, rgba(255, 255, 255, 0.97) 25px);
  padding: 40px 20px 20px;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  border: 2px solid var(--mainprimary-color);
}

.form-container .title {
  color: #222;
  font-size: 27px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  margin: 0 0 20px;
}

.form-container .title:after {
  content: '';
  background-color: var(--mainprimary-color);
  height: 3px;
  width: 110px;
  margin: 10px 0 0;
  display: block;
  clear: both;
}

.form-container .contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: -20px;
  border-bottom: 1px solid var(--mainprimary-color);
}

.form-horizontal .form-group {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0 0 15px;
  /* border-bottom: 2px solid #e7e7e7; */

}


.form-horizontal .input-icon {
  color: var(--mainprimary-color);
  font-size: 20px;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 30px;
  vertical-align: top;
  display: inline-block;
}

.form-horizontal .form-control {
  color: #222;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  width: calc(100% - 40px);
  height: 33px;
  padding: 3px 10px 0 0;
  box-shadow: none;
  border-bottom: 2px solid #e7e7e7;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  display: inline-block;
  transition: all 0.3s;
}

.form-horizontal textarea.form-control {
  height: auto;
  width: 100%;

}

.form-horizontal .form-control:focus {
  box-shadow: none;
  border: none;
}

.form-horizontal .form-control::placeholder {
  color: #b5b5b5;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}

.form-horizontal .btn {
  color: var(--mainprimary-color);
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 10px 15px 7px 20px;
  margin: 0 auto;
  border: 2px solid #e7e7e7;
  border-radius: 20px;
  display: block;
  transition: all 0.3s ease 0s;
}

.form-horizontal .btn:hover,
.form-horizontal .btn:focus {
  color: var(--mainprimary-color);
  background-color: #fff;
  border: 2px solid var(--mainprimary-color);
}

.form-horizontal .btn i {
  transition: all 0.3s ease 0s;
}

.form-horizontal .btn:hover i {
  color: var(--mainprimary-color);
  transform: translateX(3px);
}

.error {
  font-size: 12px;
  color: rgb(247, 76, 76);
  margin-top: 2px;
  margin-left: 30px;
}

button:disabled {
  opacity: 0.35;
}

.form-horizontal .form-group input.input-error,

.input-error {
  /* background-color: rgba(255,255,255,0.15);
    margin: 0 0 15px; */
  color: black;
  background-color: transparent;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  width: calc(100% - 40px);
  height: 33px;
  padding: 3px 10px 0 0;
  box-shadow: none;
  border-radius: 0;
  display: inline-block;
  transition: all 0.3s;
  border-bottom: 2px solid rgb(247, 76, 76);
  border-top: none;
  border-left: none;
  border-right: none;
}

.form-horizontal textarea.form-control,
input.input-error,
select,
.input-error {
  height: auto;
  width: 100%;
  padding-left: 30px;

}

.form-horizontal .form-group input.form-control::placeholder {
  color: rgb(157, 155, 155);
}


:root {
  --color1: #3BD2D6;
  --color2: #01E5BC;
}


.contact-map {
  width: auto;
}


/* contact end */



/* Above Arrow start */
@media (min-width:600px) {
  .downarrow-logo {
    bottom: 5px;
    right: 35px;

  }
}

.downarrow-logo {
  position: fixed;
  /* bottom: 150px;  */
  right: 35px;
  /* Adjust the distance from the right as needed */
  z-index: 999;
  /* Adjust the z-index as needed to ensure it appears above other elements */
  text-decoration: none;
  display: block;
  font-size: 30px;
  border-radius: 50%;
  padding: 10px;
  color: var(--mainprimary-color);
  transition: transform 0.3s ease;
  background-image: url('../assets/images/whitetab.png');
  /* Set your background image path here */
  background-size: contain;
  /* Adjust based on your image size */
  background-position: center;
  background-repeat: no-repeat;
  /* animation: blink 1s infinite alternate;  */
  cursor: pointer;

}

.downarrowlogo-image {
  width: 60px;
  height: 60px;
  border-radius: 10%;
  transition: transform 0.2s ease-in-out;
}

.downarrowlogo-image:hover {
  transform: scale(1.1);
}

/* ===================================================== */
/* slider section */
.hero_area {
  height: 100vh;
  /* background-image: url('../assets/images/hero-bg2.png'); */
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.hero_area::before {
  content: "";
  position: absolute;
  top: 0;

  right: 0;
  width: 45%;
  height: 88%;
  background-image: url('../assets/images/hero-bg2.png');
  background-size: cover;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.slider_section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}


.slider_section .detail-box h1 {
  font-weight: bold;
  color: var(--mainprimary-color);
  font-size: 3rem;
  padding-bottom: 15px;
  /* position: relative; */
}



.slider_section .detail-box .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px -5px 0 -5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.slider_section .detail-box .btn-box a {
  margin: 5px;
  width: 165px;
  text-align: center;
}

.slider_section .detail-box .btn-box .btn-1 {
  display: inline-block;
  padding: 10px 0px;
  background-color: #0a2050;
  color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #0a2050;
  border-radius: 5px;
}

.slider_section .detail-box .btn-box .btn-1:hover {
  background-color: transparent;
  color: #0a2050;
}

.slider_section .detail-box .btn-box .btn-2 {
  display: inline-block;
  padding: 10px 0px;
  background-color: #e7d619;
  color: #ffffff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: 1px solid #e7d619;
  border-radius: 0;
}

.slider_section .detail-box .btn-box .btn-2:hover {
  background-color: transparent;
  color: #e7d619;
}

.slider_section .img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px 0;
}

/* @media (min-width:1000px) and (max-width:1050px) {
  .slider_section .img-box img {
    margin-top: 50px;
    height: auto;
   }
} */


.slider_section #carouselExampleIndicators {
  width: 100%;
}

.slider_section .carousel-indicators {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slider_section .carousel-indicators li {
  width: 12px;
  height: 12px;
  opacity: 1;
  background: none;
  border: 2px solid #e7d619;
  color: #000000;
  position: relative;
  margin: 0 4px;
  border-radius: 100%;
}

.slider_section .carousel-indicators li.active {
  width: 18px;
  height: 18px;
}

.slider_section .li_before::before {
  content: "";
  position: absolute;
  left: -5px;
  top: 3.5px;
  width: 1.5px;
  height: 17px;
  background-color: #e7d619;
  border-radius: 5px;
  -webkit-transform: rotate(25deg);
  transform: rotate(25deg);
}

@media (max-width: 992px) {
  .hero_area {
    height: auto;
  }

  .slider_section {
    padding-bottom: 75px;
  }
}

@media (max-width: 1120px) {
  .slider_section .detail-box {
    border: none;
  }
}

/* ======================================= */
.client_section {
  /* background-image: url('https://img.freepik.com/free-photo/blank-medical-clipboard-with-stethoscope_93675-135642.jpg?w=740&t=st=1711543935~exp=1711544535~hmac=88a6b323411cd08ccc4d9d3c9f45d860858e9a362b03694b53d6e89291829a5d'); */
  background-size: cover;
  /* background: transparent; */
  background-color: rgb(238, 238, 238);
  background-attachment: fixed;
}

.client_section #carouselExampleControls {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

}

.client_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 75px;
}

.client_section .box .img-box {
  margin-right: 25px;
  min-width: 250px;
  height: 250px;
}

.client_section .box .img-box img {
  width: 100%;
  height: 70%;
  object-fit: cover;
  border-radius: 10px;
}

.client_section .box .detail-box h4 {
  text-transform: uppercase;
  font-size: 24px;
  color: var(--mainprimary-color);
  font-weight: bold;
}

.client_section .box .detail-box p {
  color: rgb(80, 75, 75);
}

.client_section .box .detail-box img {
  width: 25px;
}

.client_section .carousel_btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.client_section .carousel_btn-box .carousel-control-prev,
.client_section .carousel_btn-box .carousel-control-next {
  position: unset;
  width: 45px;
  height: 45px;
  background-color: var(--mainprimary-color);
  opacity: 1;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px;
}

.client_section .carousel_btn-box .carousel-control-prev:hover,
.client_section .carousel_btn-box .carousel-control-next:hover {
  background-color: var(--main2-color);
}

.client_section .carousel_btn-box .carousel-control-prev {
  background-image: url('../assets/images/prev.png');
}

.client_section .carousel_btn-box .carousel-control-next {
  background-image: url('../assets/images/next.png');
}

@media (max-width: 768px) {
  .client_section #carouselExampleControls {
    flex-direction: column;
    align-items: center;
  }

  .client_section .carousel_btn-box {
    margin-top: 45px;
  }
}

@media (max-width: 576px) {
  .client_section .box {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .client_section .box .img-box {
    margin: 0;
    margin-bottom: 25px;
  }
}

/* ============================ */

/* .integraty-page .content:hover{
  padding:0 10px ;
  border: 1px solid var(--mainprimary-color);
} */
.weLive h1 {
  font-size: 40px;
  color: var(--mainprimary-color);
  font-weight: 700;
}

.integraty-icon {
  font-size: 80px;
  color: var(--mainprimary-color);
}

/* ================================================ */



.reviews {
  padding: 117px 0 75px;
}

.reviews-content {
  margin-top: 36px;
}


.single-testimonial-box {
  padding: 50px 30px;
  box-shadow: 0 0px 5px rgba(71, 71, 71, .2);
  overflow: hidden;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  width: 404px;
  height: 300px;
  background: #fff;
}

.single-testimonial-box:hover {
  box-shadow: 0 10px 20px rgba(21, 19, 19, .2);
  border: 2px solid var(--mainprimary-color);
}

.slick-current .single-testimonial-box {
  box-shadow: 0 10px 20px rgba(21, 19, 19, .2);
  border: 2px solid var(--mainprimary-color);
}



/* testimonial-info */
.testimonial-info {
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.testimonial-img {
  position: relative;
  top: 11px;
  margin-right: 5px;
  border-radius: 50%;
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -ms-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

.testimonial-person {
  margin-left: 15px;
  margin-top: 11px;
}

.testimonial-person h2 {
  color: #505866;
  font-size: 18px;
}

.testimonial-person h4 {
  color: #a2a5ab;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.testimonial-person-star {
  color: #ffda2b;
  margin: 9px 4px 0 0;
}

/* testimonial-info */


/* testimonial-comment */
.testimonial-comment {
  margin-top: 18px;
}

.testimonial-comment p {
  color: #8d939e;
  font-size: 14px;
  font-weight: 300;
}

/* testimonial-comment */



@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(calc(-250px * 7));
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  background: pr;
  /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125); */
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
  padding: 20px;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: auto;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
  display: flex;
  width: calc(250px * 7);
}

.slider .slide {
  height: auto;
  /* width: 100px; */
}

/* .section {
  padding: 5% 0;
}
.section h1 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}
.section.grey {
  background-color: #f6f6f6;
} */

.section-grad {
  background: transparent -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(42%, var(--mainprimary-color)),
      color-stop(0%, transparent)) repeat scroll 0% 0%;
  background: transparent -webkit-linear-gradient(top,
      var(--mainprimary-color) 42%,
      transparent 0%) repeat scroll 0% 0%;
  background: transparent -o-linear-gradient(top, var(--mainprimary-color) 42%, transparent 0%) repeat scroll 0% 0%;
  background: transparent linear-gradient(180deg, var(--mainprimary-color) 42%, transparent 0%) repeat scroll 0% 0%;
}

.section-grad h1 {
  color: #fff;
}

/*
=========================================*/
/* General Classes */
.grad1 {
  background: var(--main2-color);
  background: -webkit-linear-gradient(220deg, var(--main2-color), #95e1cb);
  background: -o-linear-gradient(220deg, var(--main2-color), #95e1cb);
  background: linear-gradient(230deg, var(--main2-color), #95e1cb);
}

.grad2 {
  background: var(--mainprimary-color);
  background: -webkit-linear-gradient(220deg, var(--mainprimary-color), #8788cd);
  background: -o-linear-gradient(220deg, var(--mainprimary-color), #8788cd);
  background: linear-gradient(230deg, var(--mainprimary-color), #8788cd);
}

.grad3 {
  background: var(--main2-color);
  background: -webkit-linear-gradient(220deg, var(--main2-color), #95e1cb);
  background: -o-linear-gradient(220deg, var(--main2-color), #95e1cb);
  background: linear-gradient(230deg, var(--main2-color), #95e1cb);
}

.button {
  display: inline-block;
  width: 100%;
  max-width: 180px;
  padding: 8px 18px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Table Default Style */
.table-default {
  background-color: #fff;
  text-align: center;
  padding: 24px 14px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 40px;

}

.table {
  /* Table Header */
}

.table__header--icon {
  max-width: 100%;
}

.table__header--price {
  position: relative;
}

.table__content {
  /* padding: 20px 0; */
  /* Table List */
}

.table__content--list li {
  font-size: 12px;
  font-weight: 600;
  padding: 6px 0;
  text-align: left;

}

.table__content--list li.muted {
  opacity: 0.35;
}

.table__footer {
  /* margin-top: 10px; */
}

.table_amount {
  text-decoration: line-through;
  font-size: 30px;
}

/*=======================
  Table Style 1
=========================*/
.table1 {
  position: relative;
  color: #fff;
  margin-top: 80px;
  z-index: 1;
}

.table1 .table__header--title {
  position: relative;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 36px;
}

.table1 .table__header--title::after {
  content: " ";
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  width: 16px;
  height: 3px;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
}

.table1 .table__header--price {
  font-size: 46px;
  font-weight: 700;
}

.table1 .table__header--price span {
  position: relative;
  top: -20px;
  right: -10px;
  font-size: 0.45em;
}

.table1 .table__header--price::after {
  content: attr(data-price);
  position: absolute;
  top: -30px;
  left: 30px;
  width: 100%;
  color: inherit;
  font-size: 90px;
  opacity: 0.1;
}

.table1 .button {
  background-color: #fff;
  color: #333;
  border-radius: 44px;
}

.table1 .button:hover {
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.12);
}

.table1.recommended {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

/*=======================
  Table Style 3
=========================*/
/* .package-table{
  height: 800px;
} */
@media (min-width:1000px) {
  .table3 {
    /* -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.12); */
    height: 720px;
    border: 3px solid var(--mainprimary-color);
  }
}

.table3 {
  /* -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.12); */
  /* height: 720px; */
  border: 3px solid var(--mainprimary-color);
}

.table3 .table__header--icon {
  height: 80px;
  padding: 20px 0;
}

.table3 .table__header--title {
  font-size: 1.5rem;
  font-weight: 700;
}

.table3 .table__content--list {
  /* padding: 20px 0; */
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
}

.table3 .table__header--price {
  font-size: 1.6rem;
  font-weight: 700;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0, var(--mainprimary-color)),
      to(#191a77));
  background: -webkit-linear-gradient(left, var(--mainprimary-color) 0, #191a77 100%);
  background: -o-linear-gradient(left, var(--mainprimary-color) 0, #191a77 100%);
  background: linear-gradient(to right, var(--mainprimary-color) 0, #191a77 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.table3 .button {
  position: absolute;
  bottom: 40px;
  right: 0;
  left: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #4568dc;
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0, #4568dc),
      to(#b06ab3));
  background: -webkit-linear-gradient(left, #4568dc 0, #b06ab3 100%);
  background: -o-linear-gradient(left, #4568dc 0, #b06ab3 100%);
  background: linear-gradient(to right, #4568dc 0, #b06ab3 100%);
  color: #fff;
  font-size: 22px;
  padding: 0;
  border-radius: 100%;
  margin: auto;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.table3:hover .table__header--price {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.table3:hover .button {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.health-back {
  border: 2px solid var(--mainprimary-color);
  width: 100%;
  border-radius: 8px;
  margin: 20px;


}

.health-back:hover {
  background-color: var(--mainprimary-color);
  color: white;
}

/* .health-back p{
  color: var(--mainprimary-color);
  font-size: 36px;

} */
@media (min-width:1000px) {
  .health-back {
    border: 2px solid var(--mainprimary-color);
    width: 400px;
    margin: 20px;
    border-radius: 8px;

  }
}

.table-defaultlast {
  background-color: #fff;
  text-align: center;
  padding: 24px 14px;
  overflow: hidden;
  /* margin-top: 40px; */

}

/* acadamy feature */

.feature-acadamy {
  position: relative;
}

.feature-acadamy+.feature-acadamy {
  margin-top: 40px;
}

.feature-acadamy .feature-icon-acadamy {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  border: 1px solid var(--mainprimary-color);
  color: var(--mainprimary-color);
}
.feature-acadamy .feature-icon2-acadamy:hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  border: 1px solid var(--mainprimary-color);
  color: var(--mainprimary-color);
  background-color: white;
}

.feature-acadamy .feature-icon2-acadamy {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  border: 1px solid var(--mainprimary-color);
  background-color: var(--mainprimary-color);
  color: white;
}

.feature-acadamy .feature-icon-acadamy:hover {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  border: 1px solid var(--mainprimary-color);
  background-color: var(--mainprimary-color);
  color: white;
}

.feature-content-acadamy {
  padding-left: 100px;
}

#about-acadamy {
  padding: 70px 0;
}

/* -- About Img -- */
.about-acadamy-img {
  margin-top: 10px;

  
}
@media (min-width:1000px) {
  .about-acadamy-img {
    margin-top: 100px;
  
    
  }
}
.border-acadamy{
  /* position: relative; */
  border: 1px solid var(--mainprimary-color);
  /* width: 100%; */
  max-width: 30px; /* Set a maximum width if needed */
  height: 100px;
  background-color: var(--mainprimary-color);
  /* box-sizing: border-box;  */
}

.about-acadamy-img>img {
  width: 100%;
  border-radius: 20px;
  /* position: absolute; */
}
.filter-button {
  padding: 1em; /* Adjust as needed */
  font-size: 16px; /* Adjust as needed */
  background-color: #efefef;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  display: inline-block; /* Added to allow centering */
}
.filter-button:hover {
  background-color: #dadada;
}

.filter-button.active {
  background-color: #dadada;
}

.button-container {
  text-align: center; /* Centers the buttons */
}

/* gallary */

.filter-gallery {
  display: block;
}

.image-containerAcadamy {
  margin-top: 1em;
  column-count: 3; /*column-count helps to create a grid layout*/
  column-gap: 10px;
}

.gallery-item {
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  overflow: hidden; /* Ensures consistent overflow behavior */
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  transform: scale(1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Combined transition for transform and box-shadow */
}

.gallery-item img:hover {
  transform: scale(1.3);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.acadamy-gallary{
  /* font-family:"Poppins" ; */
  text-align: center;
  padding: 20px;
  color: var(--mainprimary-color);
  font-weight: 700;
  font-size: 50px;
}
.acadamy-wel{
  
  
  color: var(--mainprimary-color);
  font-weight: 700;
  font-size: 40px;
}

@media (max-width: 768px) {
  .image-containerAcadamy {
      column-count: 1; /*adjusted to 1 column for mobile*/
  }
}


/* acadamy contact */

.contactAcadamy .row{
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
}
.contactAcadamy .info-containerAcadamy {
  /* background-color: var(--main2-color); */
  height: 100%;
  padding: 20px;
  border-radius: 10px 0 0 10px;
  /* box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1); */
}
.contactAcadamy .acadamyus-header{
  font-size: 50px;
  color: var(--mainprimary-color);
 font-weight: 700;
  text-align: center;
  padding: 20px;
}

.contactAcadamy .info-itemAcadamy {
  width: 100%;
  background-color: var(--mainprimary-color);
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
}
.contactAcadamy .info-itemAcadamy a{
  color: white;
}
.contactAcadamy .info-itemAcadamy a:hover{
  color: var(--main2-color);
}

.acadamy-map iframe{
  border-radius: 16px;
  padding:  5px;
}

.contactAcadamy .info-itemAcadamy:last-child {
  margin-bottom: 0;
}

.contactAcadamy .info-itemAcadamy i {
  font-size: 20px;
  color: #fff;
  float: left;
  width: 44px;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.contactAcadamy .info-itemAcadamy h4 {
  padding: 0;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
}

.contactAcadamy .info-itemAcadamy p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.contactAcadamy .info-itemAcadamy:hover i {
  background: #fff;
  color: var(--mainprimary-color);
}
